<template> 
<div class="page-content header-clear-small">
        <div style="height:100px">
        </div>  
    <div class="card card-style create-account">
        <div class="content mb-0 text-center">
		<h4 class="text-uppercase font-600 mb-2 font-14">Créer un compte</h4>
            <div class="divider bg-orange-dark-sdw mb-3"></div>

            <!--<p class="font-11  mt-n1 mb-0">
                Pas de compte? Créez-en un.
            </p>-->

                        <div class="input-style has-icon input-style-1 input-required">
							<i class="input-icon fa fa-user font-14"></i>
							
							<input type="text" class="form-control" autocomplete="chrome-off" placeholder="Prénom" :class="{'is-invalid': errors.firstname}" v-model="newUser.firstname">
							<div class="invalid-feedback" v-if="errors.firstname">{{errors.firstname[0]}}</div>
						</div>
						


                        <div class="input-style has-icon input-style-1 input-required">
							<i class="input-icon fa fa-user font-14"></i>
							
							<input type="text" class="form-control" autocomplete="chrome-off" placeholder="Nom" :class="{'is-invalid': errors.name}" v-model="newUser.name">
							<div class="invalid-feedback" v-if="errors.name">{{errors.name[0]}}</div>
						</div> 
                       <div class="input-style has-icon input-style-1 input-required">
							<i class="input-icon fa fa-map-marker-alt"></i>
							
								<input type="text" class="form-control" autocomplete="chrome-off" placeholder="Code postal" :class="{'is-invalid': errors.zip}" v-model="newUser.zip">
							<div class="invalid-feedback" v-if="errors.zip">{{errors.zip[0]}}</div>
						</div> 	
         
         
                        <div class="input-style has-icon input-style-1 input-required">
							<i class="input-icon fa fa-map-marker-alt"></i>
							
							<input type="text" class="form-control" autocomplete="chrome-off" placeholder="Ville" :class="{'is-invalid': errors.city}" v-model="newUser.city">
							<div class="invalid-feedback" v-if="errors.city">{{errors.city[0]}}</div>
						</div> 
						
         

		
                            <div class="input-style has-icon input-style-1 input-required">
								<i class="input-icon fa fa-at"></i>
								
								<input type="text" class="form-control" autocomplete="chrome-off" placeholder="Email" :class="{'is-invalid': errors.email}" v-model="newUser.email">
							<div class="invalid-feedback" v-if="errors.email">{{errors.email[0]}}</div>
							</div>       
                        <div class="input-style has-icon input-style-1 input-required">
							<i v-if="passwordFieldType == 'password'" class="input-icon fa fa-eye font-16 mt-3 color-green-dark-sdw" @click.prevent="switchVisibility"></i>
							<i v-else class="input-icon fa fa-eye-slash font-16 mt-3 color-red-dark-sdw" @click.prevent="switchVisibility"></i>
								
							<input :type="passwordFieldType" class="form-control" placeholder="Mot de passe" :class="{'is-invalid': errors.password}" v-model="newUser.password">
							<div class="invalid-feedback" v-if="errors.password">{{errors.password[0]}}</div>
						</div>
			<div class="row mb-0 pb-0">
                    <div class="col-12">
                        <div class="form-check icon-check">
                            <input class="form-check-input" type="checkbox" value="1" id="check1" v-model="newUser.conditions">
                            <label class="form-check-label"  for="check1" style="font-size:14px; line-height:16px;"><span :class="{'text-muted': !conditions, 'is-invalid': conditions}">Je reconnais avoir pris connaissance des <a href="https://ypodo.com/conditions" target="_blank">Conditions Générales d'Utilisation</a> et de la <a href="https://ypodo.com/privacy" target="_blank">Politique de Confidentialité</a> et les accepte sans réserve</span></label>
                            <i class="icon-check-1 fa fa-square color-gray-dark font-14"></i>
                            <i class="icon-check-2 fa fa-check-square font-16 color-highlight"></i>
							
                        </div>	
						
						
                    </div>
            </div>			
            <p class="text-center pb-0 mb-3 pt-0">
                <a href="/login" class="text-center font-14">Avez-vous déjà un compte? Connectez-vous ici.</a>
            </p>
            <a href="#" @click.prevent="createAccount" class="btn btn-full btn-m shadow-l rounded-s font-500 bg-orange-dark-sdw mt-4 mb-4 color-white nice-btn">Connexion</a>
        </div>
            
    </div>	
</div>
</template>

<script>

import AppConstants from '@/config'
// @ is an alias to /src
export default {
  data() {
    return{ 
		passwordFieldType: 'password',
		loading: true,
		newUser: {},
		errors: {},
		conditions: false,	
		user: {}
		}
  }, 
  props: {
    isloggedin: {
      type: Number,
      default: 5
    }
  },   
  components: {},
  mounted() {
  }, 
  methods: {
	switchVisibility() {
		this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },   
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},
	createAccount(){
	
		if(this.newUser.conditions != 1) {
				this.conditions = true
				return false
		
		} else {
				this.conditions = false
		}
        var token = this.getConst('API_TOKEN');
		var id = this.getConst('API_ID');
		var apiUrl = this.getConst('API_URL')+"create-account"
		var apiData = { 
			'email': this.newUser.email,
			'password': this.newUser.password,
			'name': this.newUser.name,
			'firstname': this.newUser.firstname,
			'zip': this.newUser.zip,
			'city': this.newUser.city,
			'address': this.newUser.address,
			'phone': this.newUser.phone,			
			'token' : token,
			'appid': id
		}
		let axios = require('axios');
			axios.post(apiUrl, apiData)
			.then(response => {
				if (response.data.message == 'OK') {
					this.logout()
					this.$emit('openaccountsuccess', '1')
					this.newUser = {}
					this.errors = {}
				} else {
						this.errors = response.data.errors
				}
			})
			.catch(error => {
					this.errors = error.response.data.errors
			})	
	
	},	
	logout() {
		localStorage.removeItem('token')
		localStorage.removeItem('id')
		localStorage.removeItem('email')
		localStorage.removeItem('firstname')
		localStorage.removeItem('name')
		localStorage.removeItem('address')
		localStorage.removeItem('zip')
		localStorage.removeItem('city')
		localStorage.removeItem('fcm')
		localStorage.setItem('isloggedin',0 );
	}, 
}	
}
</script>	
